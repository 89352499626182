<template>
  <div class="create-product">
    <KTPortlet title="Tạo mới dịch vụ" headSize="md">
      <template v-slot:body>
        <b-form @submit.prevent="onSubmit">
          <div class="row">
            <b-form-group label="Tên" class="col-7">
              <b-form-input
                type="text"
                required
                v-model="form.name"
              ></b-form-input>
              <span class="error ml-3">{{ textError }}</span>
            </b-form-group>

            <b-form-group label="Tên viết tắt" class="col-5">
              <b-form-input type="text" v-model="form.shortName"></b-form-input>
            </b-form-group>
          </div>

          <div class="row">
            <b-form-group label="Tiệm" class="col-7">
              <v-select :options="allOutlets" multiple label="name" v-model="outLetsSelected">
                <template v-slot:no-options="{ allOutlets }">
                  <em v-if="!allOutlets">Không tìm thấy tiệm nào</em>
                </template>
              </v-select>
            </b-form-group>
            <b-form-group id="input-group-2" label="Giá" label-for="input-2" class="col-5">
              <b-form-input
                id="input-2"
                type="text"
                required
                v-model="form.price"
              ></b-form-input>
            </b-form-group>
          </div>
        </b-form>
      </template>
    </KTPortlet>
    <b-button v-on:click="onSubmit()" block variant="primary"
      >Hoàn thành</b-button
    >
  </div>
</template>

<style lang="scss"></style>

<script>
import KTPortlet from "@/components/Portlet.vue";
import OutletSelect from "@/components/outlet/Select.vue";
import { createSuccess } from "@/helper/index";
import Loader from "@/common/loader";
import vSelect from "vue-select";
import { mapGetters } from "vuex";

export default {
  name: "Create",
  components: {
    KTPortlet,
    OutletSelect,
    vSelect
  },
  data() {
    return {
      states: [
        {
          value: true,
          text: "Kích hoạt"
        },
        {
          value: false,
          text: "Chưa kích hoạt"
        }
      ],
      form: {
        name: "",
        price: 0,
        outletId: [],
        position: 0,
        shortName: ""
      },
      textError: "",
      outLetsSelected: []
    };
  },
  computed: mapGetters(["allOutlets"]),
  mounted() {
    this.getListOutlet();
  },
  methods: {
    onSubmit() {
      Loader.fire();
      const data = JSON.parse(JSON.stringify(this.form));
      if (!data.shortName) delete data.shortName;
      this.outLetsSelected.forEach((element) => {
        data.outletId.push(element._id);
      });
      this.$store
        .dispatch("createProduct", data)
        .then(() => {
          createSuccess("product");
          Loader.close()
        })
        .catch(error => {
          if (error.errors === "Product name is existed!") {
            this.textError = "Dịch vụ đã tồn tại!";
          }
          Loader.close()
        })
    },
    getListOutlet() {
      this.$store.dispatch("setOutlets", { status: 1 });
    },
  }
};
</script>
